<template>
  <Select
    ref="selectRef"
    :hideOnClick="false"
    :options="localOptions"
    :modelValue="modelProxy as SelectModelValueType"
    :multiple="multiple"
    :highlight="searchQuery"
    :noOptionsMessage="noOptionsMessage"
    v-bind="$attrs"
    class="autocomplete"
    :class="{ loading, multiple }"
    @selectOption="selectOption"
    @hide="onHide"
  >
    <template #trigger="{ disabled, open, deleteOption, selectedOptions }">
      <InputText
        ref="searchRef"
        v-model="searchQuery"
        :disabled="disabled"
        :endIcon="loading ? 'sync' : ''"
        :placeholder="placeholder"
        :icon="icon"
        :error="error"
        :variant="variant"
        disableValidation
        class="autocomplete-input"
        :dataTestId="dataTestId"
        @update:modelValue="onSearchQueryInput"
        @focus="open"
        @click="open"
      >
        <template v-if="multiple && !hideSelectedValues" #inputBefore>
          <SelectValue multiple :options="selectedOptions" :disabled="disabled" @cancel="(option) => clearOption(option, deleteOption)" />
        </template>
      </InputText>
    </template>
    <template #dropdown>
      <slot name="dropdown" />
    </template>
  </Select>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import { useDebounceFn } from '@vueuse/core'

import InputText from '../Input/InputText/InputText.vue'

import Select from '../Select/Select.vue'
import SelectValue from '../Select/SelectValue.vue'

import type { SelectModelValueType, SelectOptionType } from '../Select/types'
import type { InputWrapperVariant } from '../Input/InputWrapper/types'

const props = withDefaults(defineProps<{
  modelValue: SelectModelValueType
  minLength?: number
  search?: (val: string) => Promise<SelectOptionType[]>
  debounceDelay?: number
  placeholder?: string
  options?: SelectOptionType[]
  multiple?: boolean
  hideSelectedValues?: boolean
  icon?: string
  error?: boolean
  variant?: InputWrapperVariant
  dataTestId?: string
  noOptionsMessage?: string
}>(), {
  modelValue: '',
  minLength: 1,
  debounceDelay: 500,
  placeholder: '',
  options: () => [],
  multiple: false,
  hideSelectedValues: false,
  icon: '',
  variant: 'default',
})

const emits = defineEmits(['update:modelValue'])

const modelProxy = ref<SelectModelValueType | SelectModelValueType[]>(props.multiple ? [] : '')
const searchQuery = ref('')
const loading = ref(false)
const fetchedOptions = ref<SelectOptionType[]>([])
const searchRef = ref<InstanceType<typeof InputText> | null>(null)
const selectRef = ref<InstanceType<typeof Select> | null>(null)

function setValue() {
  emits('update:modelValue', modelProxy.value)
}

const clearOption = (option: SelectOptionType, callback: (option: SelectOptionType) => void) => {
  if (props.multiple && Array.isArray(modelProxy.value)) {
    modelProxy.value = modelProxy.value.filter(item => item !== option.value)
  }
  else {
    modelProxy.value = ''
  }
  setValue()

  callback(option)
}

const fetchOptions = useDebounceFn(async () => {
  if (searchQuery.value.length >= props.minLength && props.search) {
    loading.value = true
    const searchResult = await props.search(searchQuery.value)
    if (searchQuery.value) {
      // update fetched options only if search query is not empty on the moment of getting response
      fetchedOptions.value = searchResult
    }
    loading.value = false
  }
}, props.debounceDelay)

const localOptions = computed((): SelectOptionType[] => {
  if (props.search) {
    return fetchedOptions.value
  }

  const query = searchQuery.value.toLowerCase()
  return props.options?.filter((option) => {
    return JSON.stringify(option).toLowerCase().includes(query)
  })
})

const isEmpty = computed(() => {
  if (props.multiple && Array.isArray(modelProxy.value)) {
    return !modelProxy.value?.length
  }
  else {
    return !modelProxy.value
  }
})

const onHide = () => {
  // clear selection if the search query was changed without changing the selection
  if (!props.multiple && !isEmpty.value) {
    const selectedItem = localOptions.value.find(item => item.value === modelProxy.value)
    if (selectedItem && searchQuery.value !== selectedItem?.label) {
      modelProxy.value = ''
      setValue()
    }
  }
  // Clear input if we click outside and there is no selection
  if (isEmpty.value) {
    searchQuery.value = ''
    if (props.search) {
      fetchedOptions.value = []
    }
  }
}

const onSearchQueryInput = (newValue: string) => {
  if (!props.multiple && newValue === '' && !isEmpty.value) {
    modelProxy.value = ''
    setValue()
  }

  if (!props.search) {
    selectRef.value?.open()
  }

  if (props.search) {
    if (newValue) {
      // do no search if we just select already loaded value
      const valueInOptions = fetchedOptions.value.map(item => item.label).includes(searchQuery.value)
      if (!valueInOptions) {
        selectRef.value?.open()
        fetchOptions()
      }
    }
    else {
      fetchedOptions.value = []
      selectRef.value?.hide()
    }
  }
}

const selectOption = async (option: SelectOptionType) => {
  const label = String(option.label || option.value)

  if (props.multiple && Array.isArray(modelProxy.value)) {
    if (modelProxy.value.includes(option.value)) {
      modelProxy.value = modelProxy.value.filter(item => item !== option.value)
    }
    else {
      modelProxy.value = [...modelProxy.value, option.value]
    }
  }
  else {
    modelProxy.value = option.value
  }

  if (props.multiple) {
    searchQuery.value = ''
  }
  else {
    searchQuery.value = label
    fetchOptions()
  }

  setValue()
}

defineExpose({ selectOption })

watch(
  () => props.modelValue,
  () => {
    modelProxy.value = props.modelValue

    if (!props.multiple) {
      if (props.modelValue) {
        const option = props.options.find(item => item.value === props.modelValue)
        if (option) {
          searchQuery.value = String(option.label || option.value)
        }
      }
      else {
        searchQuery.value = ''
      }
    }
  },
  { immediate: true },
)
</script>

<style scoped>
.autocomplete.loading :deep(.end-input-icon) {
  @apply animate-spin;
}

.autocomplete.multiple :deep(.input-icon-inner) {
  @apply flex-wrap;
}

.autocomplete-input :deep(input) {
  @apply w-full truncate;
}
</style>
