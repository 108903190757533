<template>
  <InputText
    v-bind="props"
    v-model="modelValueInternal"
    class="select-panel-input"
    icon="search"
    :dataTestId="dataTestId"
    @keydown.enter.prevent
  />
</template>

<script setup lang="ts">
import { provide, ref } from 'vue'

import { useVModel } from '@vueuse/core'

import { InputText } from '../Input'
import { InputProps } from '../Input/InputText/types'

const props = withDefaults(defineProps<InputProps>(), {
  icon: 'search',
  placeholder: 'Search',
})

const modelValueInternal = useVModel(props, 'modelValue')

// TODO: rewrite form controls properly
provide('formControlHasError', ref(false))
</script>

<style scoped>
.select-panel-input.input-wrapper {
  @apply rounded-none w-[calc(100%+2px)] m-[-1px];
}
</style>
